/* General Navbar Styles */
.navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: var(--primary-color);
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    font-size: 1.25rem;
    font-weight: 600;
}

/* Navbar Brand with Logo */
.navbar-brand {
    display: flex;
    align-items: center;
    gap: 10px;
}

.navbar-brand-text {
    text-decoration: none;
    color: white;
}

.navbar-logo {
    height: 4rem;
    width: auto;
}

/* Login Button */
.login-button {
    border-radius: 4px;
    height: 44px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    padding: 0 30px;
    letter-spacing: 1px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    border: none;
    background-color: var(--accent-color);
    color: white;
    box-shadow: 0 8px 24px 0 rgba(51, 132, 254, 0.2);
}

.login-button:active,
.login-button:focus {
    background-color: white;
    color: var(--accent-color);
    box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
}
.login-button:hover {
    background-color: white;
    color: var(--accent-color);
    box-shadow: 0 8px 24px 0 rgba(16, 39, 112, 0.2);
    cursor: pointer;
}

/* User Profile */
.profile-dropdown {
    position: relative;
}

.profile-button {
    background: none;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.profile-button:hover {
    color: var(--accent-color);
}

.profile-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 10px;
}

.profile-username {
    font-size: 1.25rem;
    font-weight: 600;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    color: #333;
    border-radius: 5px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    margin-top: 1vh;
}

.dropdown-item {
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
    display: block;
}

.dropdown-item-logout {
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
    display: block;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

/* Media Query for Larger Screens (Desktops) */
@media (min-width: 1024px) {
    .navbar-brand {
        gap: 15px;
    }
}

/* Media Query for Smaller Screens (Mobile) */
@media (max-width: 480px) {
    .navbar {
        padding: 0.5rem 1rem;
    }

    .navbar-brand {
        font-size: 0.9rem;
    }

    .dropdown-menu {
        padding: 0.5rem 0;
    }
}
