.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--background-color);
}

.auth-card {
    background-color: var(--primary-color);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.auth-header {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: var(--primary-text-color);
    text-align: center;
}

.auth-input-group {
    margin-bottom: 1rem;
}

.auth-label {
    display: block;
    margin-bottom: 0.35rem;
    font-size: 0.875rem;
    color: var(--secondary-text-color);
}

.auth-input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 1rem;
}

.auth-submit-button {
    padding: 0.75rem;
    margin: 1.69420rem 0rem 0rem 0rem;
    border-radius: 4px;
    background-color: var(--accent-color);
    width: 100%;
    color: var(--primary-text-color);
    border: none;
    font-size: 1rem;
    font-weight: 600;
    font-family: inherit;
    cursor: pointer;
}

.auth-switch-button {
    margin-top: 1rem;
    background-color: transparent;
    color: var(--accent-color);
    border: none;
    cursor: pointer;
    font-size: 0.875rem;
    text-align: center;
}

.auth-error {
    margin-bottom: 1rem;
    color: #ff4d4f;
    background-color: #fff1f0;
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #ffa39e;
}
