.user-banner {
    display: flex;
    height: 20rem;
    background-color: #303030;
}

.user-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-profile {
    position: absolute;
    display: flex;
    top: 10rem;
    left: 14vw;
}

.user-avatar {
    width: 10rem;
    height: 10rem;
    border-radius: 2% 2% 0% 0%;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.user-name {
    position: relative;
    top: 6.9rem;
    left: 2vw;
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    z-index: 2;
}

.user-navbar {
    display: flex;
    justify-content: center;
    background-color: var(--primary-color);
}

.user-navbar-item {
    font-size: 1.25rem;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    padding: 0.3rem 1vw;
    margin: 0.2rem 0.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
    border-radius: 0.25rem;
    transition: background-color 0.3s;
    cursor: pointer;
}

.user-content {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 3vh;
    margin-left: 15vw;
    margin-right: 15vw;
    color: #fff;
}

.user-content-item {
    position: relative;
    padding: 1rem;
    margin-top: 1rem;
    background-color: var(--primary-color);
    border-radius: 0.25rem;
    transition: background-color 0.3s;
}

.user-content-item-title {
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-text-color);
}

.user-content-item-heading {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--primary-text-color);
}

.user-content-item-value {
    padding: 0.2rem 0rem 0rem 0.5rem;
}

.user-content-item-value.bio {
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    color: #000;
    border-radius: 4px;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
}

.user-content-item-edit-icon {
    position: absolute;
    margin-left: 4rem;
    margin-top: 0.5rem;
    cursor: pointer;
}

.connect-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin-top: 0.2rem;
    max-width: 6vw;
    border-radius: 0.25rem;
    color: var(--primary-text-color);
    transition: background-color 0.3s;
    text-decoration: none;
    cursor: pointer;
}

.connect-button.twitch {
    background-color: #6441a5;
}

.connect-button.spotify {
    background-color: #1db954;
}

.connect-button.discord {
    background-color: #7289da;
}
