@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

:root {
    --background-color: #131522;
    --primary-color: #181c36;
    --secondary-color: #175d89;
    --accent-color: #22a3f5;
    --primary-text-color: #ffffff;
    --secondary-text-color: #dfdfdf;
    --thirdy-text-color: #a8a8a8;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
    width: 0.42069vw;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
}

::-webkit-scrollbar-track {
    background-color: transparent;
}
