.notification-container {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
}

/* Default notification styling */
.notification {
    color: white;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notification p {
    margin: 0;
}

/* Close button styling */
.notification-close {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: 10px;
}

/* Different notification types */
.notification-default {
    background-color: #323232;
}

.notification-success {
    background-color: #28a745;
}

.notification-info {
    background-color: #17a2b8;
}

.notification-error {
    background-color: #dc3545;
}
