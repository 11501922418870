:root {
    --primary-text-color: #fff;
    --secondary-text-color: rgba(140, 149, 156, 1);
    --primary-btn-color: #aeaee5;
    --primary-video-color: #181c36;
    --primary-video-border-color: #14172b;
    --primary-box-shadow: 0 0 20px 4px rgba(45, 47, 52, .15), 0 4px 80px -8px rgba(45, 47, 52, .25), 0 4px 4px -2px rgba(45, 47, 52, .15);
}

.home {
    margin-top: 6rem;
    background-color: var(--background-color);
    color: white;
    text-decoration: none;
    transition: all 0.3s linear;
}

html {
    background-color: var(--background-color);
    scroll-behavior: smooth;
    color: black
}

*,
::after,
::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
