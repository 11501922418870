.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: var(--primary-color);
    padding: 20px;
    border-radius: 8px;
    max-width: 50vw;
    width: 100%;
    position: relative;
}

.modal-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
}

.modal-close {
    position: absolute;
    top: 2vh;
    right: 1vw;
    color: #fff;
    background-color: transparent;
    border: none;
    border-radius: 15%;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-close:hover {
    background-color: #b30000;
}

.modal-textarea {
    width: 100%;
    min-height: 10vh;
    max-height: 80vh;
    padding: 10px;
    margin: 10px 0;
    font-size: 1rem;
    text-wrap: wrap;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-markdown-preview {
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
}

.modal-save {
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-save:hover {
    background-color: #0056b3;
}
